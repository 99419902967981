import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import API_URL from "./urls";
import { setAccessToken, setUserName } from "src/redux/slices/DashboardSlice";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import { setLoader } from "src/redux/slices/UserRegistrationSlice";
import { toast } from "react-toastify";
import { setSelectedPlan } from "src/redux/slices/PlanSlice";
import cryptoJs from "crypto-js";

// function has SHA512 hash string
const sha512 = (value) => {
  const hash = cryptoJs.SHA512(value);
  return hash.toString(cryptoJs.enc.Hex);
};

export const signUpData = async (
  dispatch,
  username,
  email,
  password,
  company,
  plan,
  planDuration,
  mobile
) => {
  await axios({
    method: "POST",
    url: `${API_URL}signup`,
    data: {
      name: username,
      email: email,
      password: sha512(password),
      company: company,
      plan: plan,
      planDuration: planDuration,
      mobile: mobile,
    },
  }).then(
    (res) => {
      dispatch(setLoader(false));
      toast.success("Signed Up Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      localStorage.setItem("AID", res.data.AID);
      localStorage.setItem("UName", res.data.name);
      localStorage.setItem("userRole", "Administrator");
      localStorage.setItem("parentId", res.data.AID);
      localStorage.setItem("plan", plan);
      localStorage.setItem("planDuration", planDuration);
      localStorage.setItem("phone", mobile);

      dispatch(setAccessToken(res.data.AID));
      dispatch(setUserName(res.data.name));
      dispatch(setSelectedPlan(plan));
      dispatch(setLoginPage("thanking"));
    },
    (err) => {
      console.log(err);
      toast.error(`Error occured: ${err}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(setLoader(false));
    }
  );
};

function loginFunctions() {
  return <div></div>;
}

export default loginFunctions;
