import React from "react";
import banner_9 from "../../../../webassets/img/pricing-business.png";
import { useSelector } from "react-redux";

function BuisnessPlanPaymentCard() {
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );
  const showBuyAdditionalUsersModal = useSelector(
    (state) => state.plans.showBuyAdditionalUsersModal
  );
  return (
    <div className="col-lg-8 col-md-6" data-aos="zoom-in" data-aos-delay={300}>
      <div
        className="box paymentForm-boxHeight"
        // style={{ height: `${showBuyAdditionalUsersModal && "90%"}` }}
      >
        <span className="featured">Featured</span>
        <h3 style={{ color: "#ff901c" }} className="mb-0">
          Business Plan
        </h3>
        <div className="price">
          <sup>₹</sup>5999<span> +GST / mo</span>
        </div>
        <img src={banner_9} className="img-fluid" alt="" />
        <ul>
          <li>2 Administrators</li>
          <li>6 Team Members</li>
          <li>Unlimited Mobile App Accounts</li>
          <li>Unlimited Projects</li>
          <li>10GB Cloud Storage</li>
          <li>Add Storage - 10GB @ ₹250/mo</li>
          <li>Add Administrator @ ₹1499/mo</li>
          <li>Add Team Member @ ₹799/mo</li>
        </ul>
      </div>
    </div>
  );
}

export default BuisnessPlanPaymentCard;
