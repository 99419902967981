import { createSlice } from "@reduxjs/toolkit";

const LoginSlice = createSlice({
  name: "chat",
  initialState: {
    loginPage: "signIn",
  },
  reducers: {
    setLoginPage: (state, action) => {
      state.loginPage = action.payload;
    },
  },
});

export const { setLoginPage } = LoginSlice.actions;
export default LoginSlice.reducer;
