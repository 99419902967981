import { createSlice } from "@reduxjs/toolkit";

const ChatSlice = createSlice({
  name: "chat",
  initialState: {
    conversationSelectedId: 34,
    activeChat: 0,
    activePopId: null,
  },
  reducers: {
    setConversationSelectedId: (state, action) => {
      state.conversationSelectedId = action.payload;
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setActivePopId: (state, action) => {
      state.activePopId = action.payload;
    },
  },
});

export const { setConversationSelectedId, setActiveChat, setActivePopId } =
  ChatSlice.actions;
export default ChatSlice.reducer;
