import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react";
import {
  faCircleUser,
  faEye,
  faEyeSlash,
  faUser,
  faCircleInfo,
  faBriefcase,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SignUpImg from "../../assets/images/login/SignUpImage.png";
import Sliders from "./Slider";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAccessToken, setUserName } from "src/redux/slices/DashboardSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlansUI from "../PaidPlans/PlansUI";
import PaymentAddForm from "../PaymentAddForm";
import { useSelector } from "react-redux";
import {
  SetPaymentModalVisible,
  setPricingModalVisible,
} from "src/redux/slices/PlanSlice";
import {
  setCompany,
  setLoader,
  setMail,
  setName,
  setPassword,
} from "src/redux/slices/UserRegistrationSlice";
import API_URL from "src/utils/urls";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import AllPlansUI from "../PaidPlans/SignUpFlow/AllPlansUI";

// import { useNavigate } from "react-router-dom";

// const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const USER_REGEX_LETTER = /^[a-zA-Z]/;
const USER_REGEX_LENGTH = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX_LOWER = /^(?=.*[a-z])/;
const PWD_REGEX_UPPER = /^(?=.*[A-Z])/;
const PWD_REGEX_NUMBER = /^(?=.*[0-9])/;
const PWD_REGEX_SPECIAL = /^(?=.*[!@#$%])/;
const PWD_REGEX_LENGTH = /^.{8,24}$/;

// const PricingModal = ({ pricingModalVisible, onClose }) => {
//   if (!pricingModalVisible) return null;

//   return (
//     <div className="plans-modal-overlay" onClick={onClose}>
//       <div className="plans-modal" onClick={(e) => e.stopPropagation()}>
//         <div>
//           <header className="section-header">
//             <h2 style={{ fontSize: "1.2rem", marginBottom: "0rem" }}>
//               Pricing
//             </h2>
//           </header>
//           <button
//             onClick={onClose}
//             className="close-button"
//             style={{ position: "absolute", right: "-2px", top: "-8px" }}
//           >
//             &times;
//           </button>
//         </div>
//         <PlansUI />
//       </div>
//     </div>
//   );
// };

function SignUp() {
  const [agree, setAgree] = useState(false);
  // const [username, setUsername] = useState("");
  const [validUserName, setValidUserName] = useState(false);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  // const [company, setCompany] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [pwdState, setPwdState] = useState(true);
  const [cnfPwdState, setCnfPwdState] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [isInfoVisible, setInfoVisible] = useState(false);
  // const navigate = useNavigate();

  const dispatch = useDispatch();
  const infoContentRef = useRef(null);
  const handleInfoBtnToggle = () => {
    setInfoVisible(!isInfoVisible);
  };

  const pricingModalVisible = useSelector(
    (state) => state.plans.pricingModalVisible
  );
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );
  const email = useSelector((state) => state.userRegistration.mail);
  const username = useSelector((state) => state.userRegistration.name);
  const password = useSelector((state) => state.userRegistration.password);
  const company = useSelector((state) => state.userRegistration.company);
  const loading = useSelector((state) => state.userRegistration.loader);

  // useEffect(() => {
  //   const result = USER_REGEX.test(username);
  //   setValidUserName(result);
  // }, [username]);

  // useEffect(() => {
  //   const result = PWD_REGEX.test(password);
  //   setValidPassword(result);
  //   const match = password === cnfPassword;
  //   setValidMatch(match);
  // }, [password, cnfPassword]);

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!validUserName) {
    //   alert("Please insert proper username");
    //   return;
    // }
    // if (!validPassword) {
    //   alert("Please insert proper password");
    //   return;
    // }
    // if (!validMatch) {
    //   alert("Enter confirm password same as password field");
    //   return;
    // }

    // const containsLetter = USER_REGEX_LETTER.test(username);
    // const validLength = USER_REGEX_LENGTH.test(username);

    // if (!containsLetter) {
    //   toast.error("Username must start with a letter", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setValidUserName(false);
    //   return;
    // }

    // if (!validLength) {
    //   toast.error("Username should be between 4 to 24 characters, containing only letters, numbers, hyphens, and underscores", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   })
    //   setValidUserName(false);
    //   return;
    // }

    setValidUserName(true);

    // Password validation
    const hasLower = PWD_REGEX_LOWER.test(password);
    const hasUpper = PWD_REGEX_UPPER.test(password);
    const hasNumber = PWD_REGEX_NUMBER.test(password);
    const hasSpecial = PWD_REGEX_SPECIAL.test(password);
    const validLengthPwd = PWD_REGEX_LENGTH.test(password);

    if (!hasLower) {
      toast.error("Password Must Contain At Least One Lowercase Letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
      return;
    }

    if (!hasUpper) {
      toast.error("Password Must Contain At Least One Uppercase Letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
      return;
    }

    if (!hasNumber) {
      toast.error("Password Must Contain At Least One Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
      return;
    }

    if (!hasSpecial) {
      toast.error(
        "Password Must Contain At Least One Special Character (!@#$%)",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setValidPassword(false);
      return;
    }

    if (!validLengthPwd) {
      toast.error("Password Should Be Between 8 to 24 Characters", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
      return;
    }

    // Confirmation password validation
    if (password !== cnfPassword) {
      toast.error("Confirmation Password Does Not Match", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
      setValidMatch(false);
      return;
    }

    setValidPassword(true);
    setValidMatch(true);
    // setLoading(true);
    dispatch(setLoader(true));
    setAgree(false);
    checkMailExists();
  };

  const togglePwdState = () => setPwdState(!pwdState);
  const toggleCnfPwdState = () => setCnfPwdState(!cnfPwdState);

  const checkMailExists = async () => {
    await axios
      .get(`${API_URL}checkEmail`, {
        params: {
          email: email,
        },
      })
      .then((response) => {
        const result = JSON.stringify(response.data.result);
        const parsedResult = JSON.parse(result);
        if (parsedResult === "yes") {
          toast.error("Email already exists, please try another one", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAgree(false);
          // setLoading(false);
          dispatch(setLoader(false));
          // } else signUpData();
        } else {
          dispatch(setLoader(false));
          dispatch(setPricingModalVisible(true));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signUpData = async () => {
    await axios({
      method: "POST",
      url: `${API_URL}signup`,
      data: {
        name: username,
        email: email,
        password: btoa(password),
        company: company,
      },
    }).then(
      (res) => {
        console.log(res);
        // setLoading(false);
        dispatch(setLoader(false));
        toast.success("Signed Up Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.setItem("AID", res.data.AID);
        localStorage.setItem("UName", res.data.name);
        localStorage.setItem("userRole", "Administrator");
        localStorage.setItem("parentId", res.data.AID);
        dispatch(setAccessToken(res.data.AID));
        dispatch(setUserName(res.data.name));
        dispatch(setLoginPage("thanking"));
        // setLogin("thanking");
      },
      (err) => {
        console.log(err);
        alert("error found", err);
        // setLoading(false);
        dispatch(setLoader(false));
      }
    );
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    dispatch(setName(value));
    localStorage.setItem("Regusername", value);
  };

  return (
    <>
      <ToastContainer />
      {/* {pricingModalVisible && <PlansUI />} */}
      {pricingModalVisible && <AllPlansUI />}
      {paymentModalVisible && <PaymentAddForm />}
      {loading ? (
        <div style={{ height: "30rem" }}>
          <CSpinner style={{ position: "absolute", top: "50%", left: "48%" }} />
        </div>
      ) : (
        <>
          {" "}
          <CCol className="p-4 d-flex flex-column align-items-center justify-content-between form-container">
            <h2 className="form-heading">Join us Today!</h2>
            <form onSubmit={handleSubmit} className="form-elements-container">
              <div className="form-inputGroup">
                <input
                  type="text"
                  name="username"
                  autoComplete="off"
                  // onChange={(e) => dispatch(setName(e.target.value))}
                  onChange={handleNameChange}
                  required
                  placeholder="Name"
                  className="login-input signup-input"
                />
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className="form-inputGroup_Icon"
                />
              </div>
              <div className="form-inputGroup">
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  autoComplete="off"
                  onChange={(e) => dispatch(setCompany(e.target.value))}
                  className="login-input signup-input"
                  required
                />
                {/* <FontAwesomeIcon
                  icon={faCircleUser}
                  className="form-inputGroup_Icon"
                /> */}
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="form-inputGroup_Icon"
                />
              </div>
              <div className="form-inputGroup">
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Email Address"
                  onChange={(e) =>
                    dispatch(setMail(e.target.value.toLowerCase()))
                  }
                  className="login-input signup-input"
                  required
                />
                {/* <FontAwesomeIcon
                  icon={faCircleUser}
                  className="form-inputGroup_Icon"
                /> */}
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="form-inputGroup_Icon"
                />
              </div>
              <div className="form-inputGroup">
                <input
                  type={pwdState ? "password" : "text"}
                  name="password"
                  autoComplete="off"
                  onChange={(e) => dispatch(setPassword(e.target.value))}
                  placeholder="Password"
                  className="login-input signup-input"
                  required
                />
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="info-btn"
                  onMouseEnter={handleInfoBtnToggle}
                  onMouseLeave={handleInfoBtnToggle}
                />
                <p
                  ref={infoContentRef}
                  className={`info-content ${
                    isInfoVisible ? "pricingModalVisible" : "hidden"
                  }`}
                >
                  use at least 8 characters one uppercase letter one special
                  letter and one number in your password
                </p>
                <FontAwesomeIcon
                  icon={pwdState ? faEye : faEyeSlash}
                  className="form-inputGroup_Icon"
                  onClick={togglePwdState}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="form-inputGroup">
                <input
                  type={cnfPwdState ? "password" : "text"}
                  name="confirm_password"
                  autoComplete="off"
                  onChange={(e) => setCnfPassword(e.target.value)}
                  placeholder="Confirm Password"
                  className="login-input signup-input"
                  required
                />
                <FontAwesomeIcon
                  icon={cnfPwdState ? faEye : faEyeSlash}
                  className="form-inputGroup_Icon"
                  onClick={toggleCnfPwdState}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="form-terms">
                <input
                  type="checkbox"
                  id="agree"
                  onChange={checkboxHandler}
                  className="form-terms_checkbox"
                />
                <label htmlFor="agree" className="form-terms_label">
                  I have read & agree to our
                  <b>
                    <a
                      href="/termsPolicy"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      {" Terms & Conditions "}
                    </a>
                  </b>
                  and
                  <b>
                    <a
                      href="/privacy"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      {" Privacy Policy "}
                    </a>
                  </b>
                </label>
              </div>
              <CButton
                color="dark"
                shape="rounded-pill"
                className="form-inputGroup_Button"
                disabled={
                  agree &&
                  username != "" &&
                  company != "" &&
                  email != "" &&
                  password != "" &&
                  cnfPassword != ""
                    ? false
                    : true
                }
                type="submit"
              >
                Sign up
              </CButton>
            </form>
          </CCol>
          <div className="vr"></div>
          <CCol className="p-4 d-flex flex-column align-items-center justify-content-between">
            <CRow className="d-flex flex-column align-items-center justify-content-between">
              <img src={SignUpImg} className="SignUpImage" />
              <h2 className="SliderHeading my-3">Start your free trial</h2>
              <p className="SignUpPara">
                No Credit Card, No strings attached. Unlock a world of
                possibilities and experience all the premium features our
                platform has to offer.
              </p>
              <p className="SignUpPara SignUpPara2">
                Free trial - no end date, 2 User Logins on Web, 1 user login on mobile app, 500MB free space, Sandbox and
                much more
              </p>
              {/*<CButton className="SignUp-getInTouchBtn">Get in Touch</CButton>*/}
            </CRow>
          </CCol>
          <>
            {/* <button onClick={() => setPricingModalVisible(true)}>
              Open Pricing Modal
            </button> */}
            {/* <PricingModal
              pricingModalVisible={pricingModalVisible}
              onClose={() => setPricingModalVisible(false)}
              /> */}
            {/* <button onClick={() => dispatch(setPricingModalVisible(true))}>
              Open Payment form
            </button> */}
          </>
        </>
      )}
    </>
  );
}

export default SignUp;
