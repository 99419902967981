import React from "react";
import { Slider } from "infinite-react-carousel";
import SignInImg from "../../../src/assets/images/login/SignInPage.png";
import Slider2 from "../../../src/assets/images/login/slider-2.jpeg";
import Slider3 from "../../../src/assets/images/login/slider-3.jpeg";
import { CRow } from "@coreui/react";
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

function Sliders() {
  const settings = {
    arrows: false,
    arrowsBlock: false,
    className: "Slider-Container",
    duration: 100,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
      <Swiper
        modules={[Pagination, Autoplay ]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                    <img src={SignInImg} className="SliderImage" />
                    <h2 className="SliderHeading">Keep your Client happy</h2>
                    <p className="SliderPara mb-5">Transparent communication, Up-to date documentation, Proactive prediction of delays </p>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                      <img src={Slider2} className="SliderImage" />
                      <h2 className="SliderHeading">Cloudify - All at one place</h2>
                      <p className="SliderPara mb-5">Access all information on the fly in our multichannel interface in a hassle free manner</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <img src={Slider3} className="SliderImage" />
                    <h2 className="SliderHeading">Automate Repeated Tasks</h2>
                    <p className="SliderPara mb-5">Improve productivity, efficiency and save time by automating redundant activties</p>
                  </div>
                </SwiperSlide>
      </Swiper>
  );
}

export default Sliders;
