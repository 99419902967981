import { configureStore } from "@reduxjs/toolkit";
import TeamMembersSlice from "./slices/TeamMembersSlice";
import DashboardSlice from "./slices/DashboardSlice";
import DrawingSlice from "./slices/DrawingSlice";
import TaskBoardSlice from "./slices/TaskBoardSlice";
import ProjectDashboardSlice from "./slices/ProjectDashboardSlice";
import PhotosSlice from "./slices/PhotosSlice";
import PaymentInfoSlice from "./slices/PaymentInfoSlice";
import ProjectSlice from "./slices/ProjectSlice";
import ChatSlice from "./slices/ChatSlice";
import PlanSlice from "./slices/PlanSlice";
import UserRegistrationSlice from "./slices/UserRegistrationSlice";
import LoginSlice from "./slices/LoginSlice";

export const store = configureStore({
  reducer: {
    login: LoginSlice,
    project: ProjectSlice,
    teamMembers: TeamMembersSlice,
    dashboard: DashboardSlice,
    drawings: DrawingSlice,
    taskBoard: TaskBoardSlice,
    projectDashboard: ProjectDashboardSlice,
    photos: PhotosSlice,
    paymentInfo: PaymentInfoSlice,
    chat: ChatSlice,
    plans: PlanSlice,
    userRegistration: UserRegistrationSlice,
  },
});
