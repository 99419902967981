import { createSlice } from "@reduxjs/toolkit";

const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState: {
    teamMembers: [],
  },
  reducers: {
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    },
  },
});

export const { setTeamMembers } = teamMembersSlice.actions;
export default teamMembersSlice.reducer;
