import { createSlice } from "@reduxjs/toolkit";

const ProjectSlice = createSlice({
  name: "project",
  initialState: {
    weavyUrl: "",
  },
  reducers: {
    setWeavyUrl: (state, action) => {
      state.weavyUrl = action.payload;
    },
  },
});

export const { setWeavyUrl } = ProjectSlice.actions;
export default ProjectSlice.reducer;
