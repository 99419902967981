import React from "react";
import { CButton, CCol, CRow } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import ThankingImage from "../../assets/images/login/ThankingImage.png";
import facebookIcon from "../../assets/images/login/fb.png";
import linkedinIcon from "../../assets/images/login/LinkedIn.png";
import youtubeIcon from "../../assets/images/login/YouTube.png";

function PaymentFailurePage() {
  const navigate = useNavigate();
  return (
    // <div className="paymentFailure">
    //   <h2>Payment Failure Page</h2>
    //   <CButton
    //     color="dark"
    //     shape="rounded-0"
    //     className="accordion-saveBtn addProject-saveBtn"
    //     style={{ marginLeft: "1rem" }}
    //     onClick={() => navigate("/signin")}
    //   >
    //     Back to Sign Up
    //   </CButton>
    // </div>
    <div className="bg-light min-vh-100 d-flex flex-column justify-content-center SignInPage-Container">
      {/* <LoginHeader /> */}
      <div style={{ textAlign: "center", width: "100%" }}>
        <CRow className="justify-content-center">
          <CCol md={8} className="form">
            <CRow className="d-flex align-items-center">
              <div className="thankingPage">
                <img src={ThankingImage} className="SignUpImage" />
                <h2 className="thankingPage-heading">Oops! Payment Failed</h2>
                <p className="thankingPage-para">
                We encountered an issue while processing your payment through our payment gateway. Unfortunately, the transaction was not completed due to an error. This may be due to incorrect payment details, insufficient funds, or a temporary issue with the payment gateway. Please review your payment information and attempt the transaction again. If the problem persists or if you need assistance, please contact our support team for help. We apologize for any inconvenience this may have caused and appreciate your patience.
                </p>
                <CButton
                  color="dark"
                  shape="rounded-pill"
                  className="form-inputGroup_Button thankingPage-button"
                  onClick={() => {
                    navigate("/dashboard");
                    // window.location.href = "/signin";
                  }}
                >
                  Get started
                </CButton>
                <div className="svg">
                  <img src={facebookIcon} className="svgIcon" />
                  <img src={linkedinIcon} className="svgIcon" />
                  <img src={youtubeIcon} className="svgIcon" />
                </div>
              </div>
            </CRow>
          </CCol>
        </CRow>
      </div>
      {/* <LoginFooter /> */}
    </div>
  );
}

export default PaymentFailurePage;
