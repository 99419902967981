import React, { useEffect } from "react";
import { useState } from "react";
import { CButton, CCard, CCol, CFormCheck } from "@coreui/react";
import {
  faCircleUser,
  faEnvelope,
  faPhone,
  faMoneyBill,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicPlanCard from "./PaidPlans/BasicPlanCard";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  setLastUpgradeTxnId,
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
  setSelectedUpgradePlan,
  setUpgradePaymentModal,
  setUpgradePlansModal,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";
import StarterPlanCard from "./PaidPlans/StarterPlanCard";
import BuisnessPlanCard from "./PaidPlans/BuisnessPlanCard";
import { setMail, setPhone } from "src/redux/slices/UserRegistrationSlice";
import axios from "axios";
import cryptoJs from "crypto-js";
import { AddIcCall, ArrowBackIos } from "@mui/icons-material";
import API_URL from "src/utils/urls";
import { postPayUPaymentData } from "src/utils/paymentFunctions";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import BuisnessPlan from "./PaidPlans/BusinessPlan";
import UpgradeStarterPlan from "./PaidPlans/upgradePlans/UpgradeStarterPlan";
import { useNavigate } from "react-router-dom";

function UpgradePaymentForm() {
  //redux hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // payment constants
  const paymentUrl = "https://test.payu.in/_payment";
  // const txnId = "foaH3432GrzAA6c23asdwq432EJs";
  // const surl = "https://designinfinity.digitaltriumph.co.in/mywallzehnspace";
  const surl = "https://www.archiflask.com/successUrl";
  const furl = "https://www.archiflask.com/payUFail";
  const productInfo = "Plan Upgrade";

  // redux states
  const name = useSelector((state) => state.dashboard.userName);
  const phone = localStorage.getItem("phone");
  const parentId = localStorage.getItem("parentId");
  const AID = useSelector((state) => state.dashboard.accessToken);
  // const mail = "sample@mail.com";
  const mail = localStorage.getItem("mail");
  const selectedUpgradePlan = useSelector(
    (state) => state.plans.selectedUpgradePlan
  );
  // const currentPlan = useSelector((state) => state.plans.selectedPlan);
  const currentPlan = localStorage.getItem("plan");
  const currentPlanDuration = useSelector(
    (state) => state.plans.selectedPlanDuration
  );
  const selectedPlanAmount = useSelector(
    (state) => state.plans.selectedPlanAmount
  );
  const upgradePaymentModal = useSelector(
    (state) => state.plans.upgradePaymentModal
  );

  const isTodayOrPastPlanEndDate = useSelector(
    (state) => state.plans.isTodayOrPastPlanEndDate
  );
  const existingStarterPlanUpgradeAmount = useSelector(
    (state) => state.plans.existingStarterPlanUpgradeAmount
  );

  const existingStaterPlanLeftDays = useSelector(
    (state) => state.plans.existingStaterPlanLeftDays
  )

  // local states
  const [apiKey, setApiKey] = useState(null);
  const [salt, setSalt] = useState(null);
  const [txnId, setTxnId] = useState(null);
  const [hash, setHash] = useState(null);
  const [planDuration, setPlanDuration] = useState(1);
  const [SGST, setSGST] = useState(null);
  const [CGST, setCGST] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [planName, setPlanName] = useState(name);
  const [planPhone, setPlanPhone] = useState(phone);
  const [planMail, setPlanMail] = useState(mail);
  const [planDurationHidden, setPlanDurationHidden] = useState(true);
  const [existingPlanLeftDays, setExistingPlanLeftDays] = useState(null);
  const [existingPlanAmount, setExistingPlanAmount] = useState(null);
  const [gstNumber, setGstNumber] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(null);
  const [isValidGST, setIsValidGST] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [errorMessageGST, setErrorMessageGST] = useState('');
  const [errorMessageCoupon, setErrorMessageCoupon] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [AmtBeforediscountApplied, setAmtBeforediscountApplied] = useState(null);
  const [discountLimit, setDiscountLimit] = useState(0);

  // function has SHA512 hash string
  const sha512 = (value) => {
    const hash = cryptoJs.SHA512(value);
    return hash.toString(cryptoJs.enc.Hex);
  };

  // variables for payU post payment data
  let mihpayid;
  let mode;
  let status;
  let error_Message;
  let bank_ref_num;

  // funcions for order summary section
  // calculate plan amount with required fields while select plan
  const calculatePlanAmount = () => {  
    let newSubTotal;
      if (!isTodayOrPastPlanEndDate && currentPlan == "Starter") {
        // setSubTotal(Math.round(existingStarterPlanUpgradeAmount));
        // setSGST(Math.round((subTotal * 9) / 100));
        // setCGST(Math.round((subTotal * 9) / 100));
        // setTotalAmount(Math.round(subTotal + SGST + CGST));
  
         newSubTotal = Math.round(existingStarterPlanUpgradeAmount);
        } 
        else {
          newSubTotal = Math.round(selectedPlanAmount * planDuration);
        } 
        
  
    // Calculate the initial SGST and CGST
    let newSGST = ((newSubTotal * 9) / 100).toFixed(2);
    let newCGST = ((newSubTotal * 9) / 100).toFixed(2);
  
    let discountAmount = 0;
    setAmtBeforediscountApplied(newSubTotal);
    if (isCouponValid && discountPercentage > 0) {
      
      // Calculate the discount amount
      discountAmount = ((newSubTotal * discountPercentage) / 100).toFixed(2);

      // Ensure the discount amount does not exceed the discount  
      if (discountLimit > 0 && parseFloat(discountAmount) > discountLimit) {
        discountAmount = discountLimit.toFixed(2);
      }
      // Apply discount to subtotal
      newSubTotal = (parseFloat(newSubTotal) - parseFloat(discountAmount)).toFixed(2);
      // Update state      
      setDiscountApplied(discountAmount > 0 ? discountAmount : null);
      setDiscountPercentage(discountPercentage > 0 ? discountPercentage : null);
    }
  
    // Recalculate SGST and CGST based on the updated subtotal
    newSGST = ((newSubTotal * 9) / 100).toFixed(2);
    newCGST = ((newSubTotal * 9) / 100).toFixed(2);
    setSGST(newSGST);
    setCGST(newCGST);

    // Calculate total amount after discount and taxes
    const totalAmount = (
      parseFloat(newSubTotal) +
      parseFloat(newSGST) +
      parseFloat(newCGST)
    ).toFixed(2);
        
    
    
    setSubTotal(newSubTotal);
    
     setTotalAmount(totalAmount); // Set the total amount after discount
       };

     // Handle GST number validation
     const validateGSTNumber = () => {
      const gstPattern = /^[A-Z0-9]{15}$/;
      let isValid = true;
  
      if (gstNumber.length !== 15) {
        setErrorMessageGST('GST number must be exactly 15 characters long.');
        isValid = false;
      } else if (!gstPattern.test(gstNumber)) {
        setErrorMessageGST('GST number should contain only alphanumeric characters with no spaces or special characters.');
        isValid = false;
      } else {
        setErrorMessageGST('');
        isValid = true;
      }
      setIsValidGST(isValid);
      return isValid;
    };
  
    const handleGstChange = (e) => {
      setGstNumber(e.target.value.toUpperCase());
      validateGSTNumber(); // Validate GST number on every change
    };

  // Function to handle coupon code validation
  const validateCouponCode = async () => {
    if (couponCode.length>4) {
    try {
      let urlForCouponCheck = API_URL+"checkCouponCode";
      const response = await axios.get(urlForCouponCheck, {
        params: { 
          coupon: couponCode, 
          AID: AID ,
          
       }
      });
      console.log('API Response:', response.data);


      if (response.data.result === "Success" && response.data.coupon === "valid") {
        setIsCouponValid(true);
        setDiscountPercentage(response.data.off);
        setDiscountLimit(response.data.limit);
       // Extract the discount percentage and limit from the API response
      //const discountPercentage = response.data.off;
      //const discountLimit = response.data.limit;

        toast.success('Coupon code applied successfully.');
        //calculatePlanAmount(); // Pass the discount percentage from the API to the calculation function
      } else {
        setIsCouponValid(false);
        //setDiscountPercentage(0); // Reset the discount percentage if the coupon is invalid

        toast.error('Invalid coupon code.');
        
      }
    } catch (error) {
      setIsCouponValid(false);
      //setDiscountPercentage(0); // Reset the discount percentage on error

      toast.error('Error validating coupon code.');
      console.error(error);
    }
  }
    else {
       // Update state      
    setDiscountApplied(null);
    setDiscountPercentage(null);
    setDiscountLimit(null);
    }
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateGSTNumber()) {
        alert('GST number is valid');
      }
    };

  const handleRadioButtonChange = (e) => {
    setPlanDuration(Number(e.target.value));
    calculatePlanAmount();
  };

  const getPayUCredentials = async () => {
    await axios
      .get(`${API_URL}/getPayuTestTokens`)
      .then((response) => {
        console.log("payu credentials data response ", response.data);
        setApiKey(atob(response.data.MID));
        setSalt(atob(response.data.Salt));
        setTxnId(response.data.txnID);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const signUpPaymentAPI = async (event) => {
    try {
      await axios
        .post(`${API_URL}/postPayuPaymentData`, {
          AID: parentId,
          txnid: txnId,
          productinfo: productInfo,
          amount: totalAmount,
          email: planMail,
          firstname: planName,
          surl: surl,
          furl: furl,
          hash: hash,
          phone: planPhone,
          mihpayid: mihpayid,
          mode: mode,
          status: status,
          error_Message: error_Message,
          bank_ref_num: String(bank_ref_num),
          gst: gstNumber.length = 15 ? gstNumber : null,
          coupon:couponCode.length > 4 ? couponCode : null,
          discount: discountApplied > 0 ? discountApplied : null,
        })
        .then(function (response) {
          console.log(
            "post payU payment data success response for upgrade subscription:",
            response
          );

          if ((response.data = "success")) {
          }
          return response;
        })
        .catch(function (error) {
          console.log("post payU payment data failure response:", error);
          return response;
        });
    } catch (error) {
      console.log("error occured:", error);
      // event.target.submit();
    }
  };

  const handlePaymentFormSubmit = () => {
    // event.preventDefault();
    console.log("submit upgrade plan form");
    localStorage.setItem("selectedUpgradePlan", selectedUpgradePlan);
    localStorage.setItem(
      "selectedUpgradePlanDuration",
      !planDurationHidden ? 0 : planDuration
    );
    localStorage.setItem("upgradePlanAmountBeforeTax", subTotal);
    localStorage.setItem("upgradePlanAmountAfterTax", totalAmount);
    localStorage.setItem("lastUpgradeTxnId", txnId);
    var data = {
      key: apiKey,
      txnid: txnId,
      amount: totalAmount,
      productinfo: productInfo,
      firstname: planName,
      email: planMail,
      phone: planPhone,
      surl: surl,
      furl: furl,
      hash: hash,
    };
    var handlers = {
      responseHandler: async function (BOLT) {
        if (BOLT.response.txnStatus == "SUCCESS") {
          console.log("Your payment has been successful");
          // setMihpayid(BOLT.response.mihpayid);
          // setMode(BOLT.response.mode);
          // setStatus(BOLT.response.status);
          // setError_Message(BOLT.response.error_Message);
          // setBank_ref_num(BOLT.response.bank_ref_num);
          mihpayid = BOLT.response.mihpayid;
          mode = BOLT.response.mode;
          status = BOLT.response.status;
          error_Message = BOLT.response.error_Message;
          bank_ref_num = BOLT.response.bank_ref_num;
          await signUpPaymentAPI();
          dispatch(setUpgradePaymentModal(false));
          // event.target.submit();
          navigate("/successUrl");
        }
        if (BOLT.response.txnStatus == "FAILED") {
          console.log("Payment failed. Please try again.");
          mihpayid = BOLT.response.mihpayid;
          mode = BOLT.response.mode;
          status = BOLT.response.status;
          error_Message = BOLT.response.error_Message;
          bank_ref_num = BOLT.response.bank_ref_num;
          await signUpPaymentAPI();
          dispatch(setUpgradePaymentModal(false));
          // event.target.submit();
          navigate("/payUFail");
        }
        if (BOLT.response.txnStatus == "CANCEL") {
          console.log("Payment failed. Please try again.");
          mihpayid = BOLT.response.mihpayid;
          mode = BOLT.response.mode;
          status = BOLT.response.status;
          error_Message = BOLT.response.error_Message;
          bank_ref_num = BOLT.response.bank_ref_num;
          await signUpPaymentAPI();
          dispatch(setUpgradePaymentModal(false));
          // event.target.submit();
          navigate("/payUFail");
        }
      },
      catchException: function (BOLT) {
        console.log("Payment failed. Please try again.");
        // event.target.submit();
        dispatch(setUpgradePaymentModal(false));
        navigate("/payUFail");
      },
    };
    bolt.launch(data, handlers);
    // event.target.submit();
  };

  // const handlePaymentFormSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios
  //       .post(`${API_URL}/UpgradeSubscription`, {
  //         AID: parentId,
  //         plan: selectedUpgradePlan,
  //         duration: planDurationHidden ? "11" : planDuration,
  //         amountbeforeTax: subTotal,
  //         amountafterTax: totalAmount,
  //         payuTxnid: txnId,
  //       })
  //       .then(function (response) {
  //         console.log(
  //           "additional Storage payment data success response:",
  //           response
  //         );

  //         return response;
  //       })
  //       .catch(function (error) {
  //         console.log(
  //           "additional Storage  payment data failure response:",
  //           error
  //         );
  //         return error;
  //       });
  //     if (response.data.Result == "success") {
  //       // If the API call is successful, submit the form
  //       dispatch(setLastUpgradeTxnId(txnId));
  //       sessionStorage.setItem("lastUpgradeTxnId", txnId);
  //       event.target.submit();
  //     } else {
  //       // Handle API error
  //       console.error("API call failed");
  //     }
  //   } catch (error) {
  //     console.log("error occured:", error);
  //   }

  //   // sessionStorage.setItem("phone", phone);
  // };

  // Use Effect section
  useEffect(() => {
    getPayUCredentials();
    console.log("isToday or past plan end date", isTodayOrPastPlanEndDate);
    if (!isTodayOrPastPlanEndDate && currentPlan == "Starter") {
      setPlanDurationHidden(false);
    }
    if (currentPlan == "Free") {
      setPlanDurationHidden(true);
    }
  }, []);

  useEffect(() => {
    calculatePlanAmount();
  },[planDuration, isCouponValid]);

  useEffect(() => {
    if (apiKey != null) {
      setHash(
        sha512(
          `${apiKey}|${txnId}|${totalAmount}|${productInfo}|${name}|${mail}|||||||||||${salt}`
        )
      );
    }
  }, [apiKey, totalAmount]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = " https://jssdk-uat.payu.in/bolt/bolt.min.js"; //"https://jssdk.payu.in/bolt/bolt.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   if (currentPlan == "Starter") {
  //     getUpgradePlanDetails();
  //   }
  // });

  if (!upgradePaymentModal) return null;
  return (
    <div
      className="plans-modal-overlay"
      onClick={() => {
        dispatch(setUpgradePaymentModal(false));
        dispatch(setSelectedUpgradePlan(null));
      }}
    >
      <div className="plans-modal" onClick={(e) => e.stopPropagation()}>
        <div
          className="webpage plans-modal-body"
          onClick={(e) => e.stopPropagation()}
        >
          <section
            id="pricing"
            className="pricing"
            style={{ padding: "20px 0px 0px 0px" }}
          >
            <div
              className="container h-100"
              data-aos="fade-up"
              style={{ height: selectedUpgradePlan != null && "95%" }}
            >
              <div className="row gy-4 h-100" data-aos="fade-left">
                <span
                  onClick={() => {
                    dispatch(setSelectedUpgradePlan(null));
                    dispatch(setUpgradePlansModal(true));
                    dispatch(setUpgradePaymentModal(false));
                  }}
                >
                  <ArrowBackIos className="pricing-backButton" />
                </span>
                <div className="h-100 w-50 d-flex justify-content-center border-end border-end-2">
                  {selectedUpgradePlan == "Free" && <BasicPlanCard />}
                  {selectedUpgradePlan == "Starter" && <UpgradeStarterPlan />}
                  {selectedUpgradePlan == "Business" && <BuisnessPlan />}
                </div>
                <CCol
                  className="p-4 d-flex flex-column align-items-center justify-content-between"
                  style={{ margin: "0rem", textAlign: "left" }}
                >
                  <form className="form-elements-container">
                    <div>
                      <h3>Enter Details</h3>
                      <div className="form-inputGroup">
                        <input
                          type="text"
                          name="name"
                          autoComplete="off"
                          value={planName}
                          required
                          placeholder="Name"
                          onChange={(e) => setPlanName(e.target.value)}
                          // disabled
                          className="login-input signup-input plan-input"
                        />
                        <FontAwesomeIcon
                          icon={faCircleUser}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                      <div className="form-inputGroup">
                        <input
                          type="email"
                          name="email"
                          autoComplete="off"
                          placeholder="Email Address"
                          value={planMail}
                          onChange={(e) => setPlanMail(e.target.value)}
                          className="login-input signup-input plan-input"
                          required
                          // disabled
                        />
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                      <div className="form-inputGroup">
                        <input
                          type="number"
                          name="phone"
                          autoComplete="off"
                          value={planPhone}
                          onChange={(e) => setPlanPhone(e.target.value)}
                          required
                          placeholder="Mobile"
                          className="login-input signup-input plan-input"
                        />
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                      <div className="form-inputGroup">
                        <input
                          type="text"
                          name="gst"
                          autoComplete="off"
                          value={gstNumber}
                          maxLength={15}
                          onChange={handleGstChange}
                          onBlur={validateGSTNumber}
                          placeholder="GST Number"
                          className="login-input signup-input plan-input"
                        />
                        <FontAwesomeIcon
                          icon={faPercent}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                      <div className="form-inputGroup">
                        <input
                          type="text"
                          name="coupon"
                          autoComplete="off"
                          value={couponCode}
                  onChange={handleCouponChange}
                  onBlur={validateCouponCode}
                          placeholder="Coupon Code"
                          className="login-input signup-input plan-input"
                        />
                        <FontAwesomeIcon
                          icon={faMoneyBill}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                    </div>
                    {planDurationHidden && (
                      <div
                        className="d-flex flex-column align-items-start justify-content-center h-100 payment-radio-button plan-input"
                        style={{ marginLeft: "0.2rem" }}
                      >
                        <span style={{ marginBottom: "0.2rem" }}>
                          Plan Duration (In Months)
                        </span>
                        <div className="form-inputGroup">
                          <CFormCheck
                            inline
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineCheckbox1"
                            value="1"
                            label="1"
                            checked={planDuration === 1}
                            onChange={handleRadioButtonChange}
                            defaultChecked
                          />
                          <CFormCheck
                            inline
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineCheckbox2"
                            value="3"
                            label="3"
                            checked={planDuration === 3}
                            onChange={handleRadioButtonChange}
                          />
                          <CFormCheck
                            inline
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineCheckbox3"
                            value="6"
                            label="6"
                            checked={planDuration === 6}
                            onChange={handleRadioButtonChange}
                          />
                          <CFormCheck
                            inline
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineCheckbox4"
                            value="12"
                            label="12"
                            checked={planDuration === 12}
                            onChange={handleRadioButtonChange}
                          />
                        </div>
                      </div>
                    )}
                    {/* order summary */}
                    <div className="order-summary">
                      <h3>Order Summary</h3>
                      <div
                        className="order-summary-itemsDiv"
                        style={{ fontSize: "15px" }}
                      >
                        <div className="order-summary-items">
                          <span>Plan Amount Per Month: </span>
                          <span>₹{selectedPlanAmount}</span>
                        </div>
                        {planDurationHidden && (
                          <div className="order-summary-items">
                            <span>
                              Plan Duration: {planDuration}{" "}
                              {planDuration == 1 ? "Month" : "Months"}
                            </span>
                            <span></span>
                          </div>
                        )}
                        {!planDurationHidden && (
                          <div className="order-summary-items">
                            <span>
                            <small>
                             Pro Rata for Days({existingStaterPlanLeftDays}) left in current plan:</small> 
                            </span><span>₹{(AmtBeforediscountApplied-selectedPlanAmount).toFixed(2)}</span>
                            
                          </div>
                        )}
                        {discountApplied && (
                        <div className="order-summary-items">
                         <span>Discount Applied: ({discountPercentage}%) </span>
                         <span>- ₹{discountApplied}</span>
                        </div>
                        )}
                        <div className="order-summary-items border-top border-top-2 border-bottom border-bottom-2">
                          <span>Subtotal: </span>
                          <span>₹{subTotal}</span>
                        </div>
                        <div className="order-summary-items">
                          <span>SGST: (9%) </span>
                          <span>₹{SGST}</span>
                        </div>
                        <div className="order-summary-items">
                          <span>CGST: (9%) </span>
                          <span>₹{CGST}</span>
                        </div>
                        <div className="order-summary-items fw-bold">
                          <span>Total Amount: </span>
                          <span>₹{totalAmount}</span>
                        </div>
                      </div>
                    </div>
                    <CButton
                      color="dark"
                      shape="rounded-pill"
                      className="form-inputGroup_Button"
                      onClick={handlePaymentFormSubmit}
                      style={{ marginBottom: "2rem" }}
                      // disabled={name != "" && mail != ""}
                      type="submit"
                    >
                      Buy now
                    </CButton>
                  </form>
                  {/* <form
                    action={paymentUrl}
                    method="post"
                    onSubmit={handlePaymentFormSubmit}
                    style={{ marginBottom: "1rem" }}
                  >
                    <input type="hidden" name="key" value={apiKey} />
                    <input type="hidden" name="txnid" value={txnId} />
                    <input
                      type="hidden"
                      name="productinfo"
                      value={productInfo}
                    />
                    <input type="hidden" name="amount" value={totalAmount} />
                    <input type="hidden" name="email" value={mail} />
                    <input type="hidden" name="firstname" value={name} />
                    <input type="hidden" name="surl" value={surl} />
                    <input type="hidden" name="furl" value={furl} />
                    <input type="hidden" name="phone" value={phone} />
                    <input type="hidden" name="hash" value={hash} />
                    <input
                      type="submit"
                      value="Pay now"
                      className="form-inputGroup_Button"
                    />
                  </form> */}
                </CCol>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default UpgradePaymentForm;