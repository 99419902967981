import React from "react";
import banner_9 from "../../webassets/img/pricing-business.png";
import { useDispatch } from "react-redux";
import {
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
  setSelectedPlanAmount,
  setSelectedUpgradePlan,
  setUpgradePaymentModal,
  setUpgradePlansModal,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";

function BuisnessPlan() {
  const dispatch = useDispatch();
  const selectedUpgradePlan = useSelector(
    (state) => state.plans.selectedUpgradePlan
  );
  const currentPlan = useSelector((state) => state.dashboard.plan);

  return (
    <div
      className={`${
        selectedUpgradePlan == "Business" ? "col-lg-8" : "col-lg-6"
      } ${
        currentPlan == "Starter" && selectedUpgradePlan == null
          ? "col-lg-9"
          : "col-lg-6"
      } col-md-6 paymentForm-allUICard `}
      data-aos="zoom-in"
      data-aos-delay={300}
      style={{
        margin:
          currentPlan == "Starter" && selectedUpgradePlan == null ? "auto" : "",
        marginTop: selectedUpgradePlan == null && "2rem",
      }}
    >
      <div
        className={`${selectedUpgradePlan == null ? "pb-3" : ""} box ${
          currentPlan == "Starter" ? "upgrade-business-box" : ""
        }${selectedUpgradePlan != null ? "h-90" : "paymentForm-box"}`}
        //style={{ height: `${selectedUpgradePlan == null && "auto"}` }}
        style={{ height: "auto" }}
      >
        <span className="featured">Featured</span>
        <h3 style={{ color: "#ff901c" }} className="mb-0">
          Business Plan
        </h3>
        <div className="price">
          <sup>₹</sup>5999<span> +GST / mo</span>
        </div>
        <img src={banner_9} className="img-fluid" alt="" />
        <ul>
          <li>2 Administrators</li>
          <li>6 Team Members</li>
          <li>Unlimited Mobile App Accounts</li>
          <li>Unlimited Projects</li>
          <li>10GB Cloud Storage</li>
          <li>Add Storage - 10GB @ ₹250/mo</li>
          <li>Add Administrator @ ₹1499/mo</li>
          <li>Add Team Member @ ₹799/mo</li>
        </ul>

        {selectedUpgradePlan == null && (
          <a
            href="#"
            className="btn-buy mt-0"
            onClick={() => {
              dispatch(setSelectedUpgradePlan("Business"));
              dispatch(setSelectedPlanAmount(5999));
              dispatch(setUpgradePlansModal(false));
              dispatch(setUpgradePaymentModal(true));
              localStorage.setItem("selectedPlan", "Business");
            }}
          >
            Select
          </a>
        )}
      </div>
    </div>
  );
}

export default BuisnessPlan;
