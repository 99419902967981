import { createSlice } from "@reduxjs/toolkit";

const projectDashboardSlice = createSlice({
  name: "projectdashboard",
  initialState: {
    projectTeamMembers: "",
    projectName: "",
  },
  reducers: {
    setProjectTeamMembers: (state, action) => {
      state.projectTeamMembers = action.payload;
    },
    setProjectName: (state, action) => {
      state.projectName = action.payload;
    },
  },
});

export const { setProjectTeamMembers, setProjectName } =
  projectDashboardSlice.actions;
export default projectDashboardSlice.reducer;
