import React, { useEffect } from "react";
import { useState } from "react";
import { CButton, CCard, CCol, CFormCheck } from "@coreui/react";
import {
  faCircleUser,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicPlanCard from "./PaidPlans/BasicPlanCard";
import { useDispatch } from "react-redux";
import {
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";
import StarterPlanCard from "./PaidPlans/StarterPlanCard";
import BuisnessPlanCard from "./PaidPlans/BuisnessPlanCard";
import { setMail, setPhone } from "src/redux/slices/UserRegistrationSlice";
import axios from "axios";
import cryptoJs from "crypto-js";
import { AddIcCall, ArrowBackIos } from "@mui/icons-material";
import API_URL from "src/utils/urls";
import { postPayUPaymentData } from "src/utils/paymentFunctions";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import FreePlanCard from "./PaidPlans/SignUpFlow/AllPlansCard/FreePlanCard";
import BuisnessPlanPaymentCard from "./PaidPlans/SignUpFlow/AllPlansCardForPaymentForm/BusinessPlanPaymentCard";
import StarterPlanPaymentCard from "./PaidPlans/SignUpFlow/AllPlansCardForPaymentForm/StarterPlanPaymentCard";
import { useNavigate } from "react-router-dom";

function PaymentAddForm() {
  //redux hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // payment constants
  const paymentUrl = "https://test.payu.in/_payment";
  // const txnId = "foaH3432GrzAA6c23asdwq432EJs";
  const surl = "https://www.archiflask.com/successSignUp";
  const furl = "https://www.archiflask.com/payUFail";
  const productInfo = "ArchiFlask Subscription";

  // redux states
  const name = useSelector((state) => state.userRegistration.name);
  const mail = useSelector((state) => state.userRegistration.mail);
  const selectedPlan = useSelector((state) => state.plans.selectedPlan);
  const selectedPlanAmount = useSelector(
    (state) => state.plans.selectedPlanAmount
  );
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );
  const email = useSelector((state) => state.userRegistration.mail);
  const username = useSelector((state) => state.userRegistration.name);
  const password = useSelector((state) => state.userRegistration.password);
  const company = useSelector((state) => state.userRegistration.company);
  const phone = useSelector((state) => state.userRegistration.phone);
  localStorage.setItem("email", email);
  localStorage.setItem("username", username);
  localStorage.setItem("password", password);
  localStorage.setItem("company", company);

  // local states
  const [apiKey, setApiKey] = useState(null);
  const [salt, setSalt] = useState(null);
  const [txnId, setTxnId] = useState(null);
  const [hash, setHash] = useState(null);
  const [planDuration, setPlanDuration] = useState(1);
  const [SGST, setSGST] = useState(null);
  const [CGST, setCGST] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  // const [mihpayid, setMihpayid] = useState("");
  // const [mode, setMode] = useState("");
  // const [status, setStatus] = useState("");
  // const [error_Message, setError_Message] = useState("");
  // const [bank_ref_num, setBank_ref_num] = useState("");

  let mihpayid;
  let mode;
  let status;
  let error_Message;
  let bank_ref_num;

  // function has SHA512 hash string
  const sha512 = (value) => {
    const hash = cryptoJs.SHA512(value);
    return hash.toString(cryptoJs.enc.Hex);
  };

  // funcions for order summary section
  // calculate plan amount with required fields while select plan
  const calculatePlanAmount = () => {
    const newSubTotal = Math.round(selectedPlanAmount * planDuration).toFixed(
      2
    );
    const newSGST = ((newSubTotal * 9) / 100).toFixed(2);
    const newCGST = ((newSubTotal * 9) / 100).toFixed(2);
    const newTotalAmount = (
      parseFloat(newSubTotal) +
      parseFloat(newSGST) +
      parseFloat(newCGST)
    ).toFixed(2);

    setSubTotal(newSubTotal);
    setSGST(newSGST);
    setCGST(newCGST);
    setTotalAmount(newTotalAmount);
  };

  const handleRadioButtonChange = (e) => {
    setPlanDuration(Number(e.target.value));
    calculatePlanAmount();
  };

  const getPayUCredentials = async () => {
    await axios
      .get(`${API_URL}/getPayuTestTokens`)
      .then((response) => {
        console.log("payu credentials data response ", response.data);
        setApiKey(atob(response.data.MID));
        setSalt(atob(response.data.Salt));
        setTxnId(response.data.txnID);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const validateForm = () => {
    return name !== "" && mail !== "";
  };

  function makePayment() {
    // var apiKey = "your_api_key";
    // var salt = "your_salt";
    // var txnId = "your_transaction_id";
    // var amount = "100.00";
    // var productInfo = "Test Product";
    // var firstName = "John";
    // var email = "john@example.com";
    // var phone = "9999999999";
    // var surl = "http://example.com/success";
    // var furl = "http://example.com/failure";
    // var hashString = apiKey + "|" + txnId + "|" + amount + "|" + productInfo + "|" + firstName + "|" + email + "|||||||||||" + salt;
    // var hash = sha512(hashString);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", paymentUrl, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log("sign up xml request response", xhr.responseText);
        localStorage.setItem("payUResponse", xhr.responseText);
      }
    };

    var formData = new FormData();
    formData.append("key", apiKey);
    formData.append("txnid", txnId);
    formData.append("amount", totalAmount);
    formData.append("productinfo", productInfo);
    formData.append("firstname", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("surl", surl);
    formData.append("furl", furl);
    formData.append("hash", hash);

    xhr.send(formData);
  }

  const signUpPaymentAPI = async () => {
    try {
      await axios
        .post(`${API_URL}/postPayuPaymentData`, {
          AID: mail,
          txnid: txnId,
          productinfo: productInfo,
          amount: totalAmount,
          email: mail,
          firstname: name,
          surl: surl,
          furl: furl,
          hash: hash,
          phone: phone,
          mihpayid: mihpayid,
          mode: mode,
          status: status,
          error_Message: error_Message,
          bank_ref_num: String(bank_ref_num),
        })
        .then(function (response) {
          console.log("post payU payment data success response:", response);
          return response;
          if ((response.data = "success")) {
            // sessionStorage.setItem("selectedPlanDuration", null);
            // sessionStorage.setItem("loginPage", null);
            // updatePaymentStatus();
            // event.target.submit();
          }
        })
        .catch(function (error) {
          console.log("post payU payment data failure response:", error);
          return response;
          // event.target.submit();
        });
    } catch (error) {
      console.log("error occured:", error);
      // event.target.submit();
    }
  };

  const handlePaymentFormSubmit = () => {
    // event.preventDefault();
    if (name == "" || mail == "") {
      alert("Please fill out all required fields.");
      return;
    }
    localStorage.setItem("signUpAID", mail);
    localStorage.setItem("signUpTxnId", txnId);
    localStorage.setItem("signUpTotalAmount", totalAmount);
    localStorage.setItem("signUpProductinfo", productInfo);
    localStorage.setItem("signUpMail", mail);
    localStorage.setItem("signUpName", name);
    localStorage.setItem("signUpSurl", surl);
    localStorage.setItem("signUpFurl", furl);
    localStorage.setItem("signUpHash", hash);
    localStorage.setItem("signUpPhone", phone);
    localStorage.setItem("selectedPlanDuration", planDuration);
    // localStorage.setItem("loginPage", "thanking");
    // dispatch(setLoginPage("thanking"));
    // event.target.submit();
    // makePayment();
    // handlePayment();
    var data = {
      key: apiKey,
      txnid: txnId,
      amount: totalAmount,
      productinfo: productInfo,
      firstname: name,
      email: email,
      phone: phone,
      surl: surl,
      furl: furl,
      hash: hash,
    };
    var handlers = {
      responseHandler: async function (BOLT) {
        if (BOLT.response.txnStatus == "SUCCESS") {
          console.log("Your payment has been successful");
          console.log(BOLT.response);
          // setMihpayid(BOLT.response.mihpayid);
          // setMode(BOLT.response.mode);
          // setStatus(BOLT.response.status);
          // setError_Message(BOLT.response.error_Message);
          // setBank_ref_num(BOLT.response.bank_ref_num);
          mihpayid = BOLT.response.mihpayid;
          mode = BOLT.response.mode;
          status = BOLT.response.status;
          error_Message = BOLT.response.error_Message;
          bank_ref_num = BOLT.response.bank_ref_num;
          await signUpPaymentAPI();
          navigate("/successSignUp");
          // event.target.submit();
        }
        if (BOLT.response.txnStatus == "FAILED") {
          console.log("Payment failed. Please try again.");
          mihpayid = BOLT.response.mihpayid;
          mode = BOLT.response.mode;
          status = BOLT.response.status;
          error_Message = BOLT.response.error_Message;
          bank_ref_num = BOLT.response.bank_ref_num;
          await signUpPaymentAPI();
          // event.target.submit();
          navigate("/payUFail");
        }
        if (BOLT.response.txnStatus == "CANCEL") {
          console.log("Payment failed. Please try again.");
          mihpayid = BOLT.response.mihpayid;
          mode = BOLT.response.mode;
          status = BOLT.response.status;
          error_Message = BOLT.response.error_Message;
          bank_ref_num = BOLT.response.bank_ref_num;
          await signUpPaymentAPI();
          // event.target.submit();
          navigate("/payUFail");
        }
      },
      catchException: function (BOLT) {
        console.log("Payment failed. Please try again.");
        navigate("/payUFail");
        // event.target.submit();
      },
    };
    bolt.launch(data, handlers);
  };
  // if (!validateForm()) {
  //   alert("Please fill out all required fields.");
  // sessionStorage.setItem("phone", phone);
  // try {
  //   const response = await axios
  //     .post(`${API_URL}/postPayuPaymentData`, {
  //       AID: mail,
  //       txnid: txnId,
  //       productinfo: productInfo,
  //       amount: totalAmount,
  //       email: mail,
  //       firstname: name,
  //       surl: surl,
  //       furl: furl,
  //       hash: hash,
  //       phone: phone,
  //     })
  //     .then(function (response) {
  //       console.log("post payU payment data success response:", response);
  //       return response;
  //     })
  //     .catch(function (error) {
  //       console.log("post payU payment data failure response:", error);
  //       return error;
  //     });
  //   console.log("response in payment add form", response);
  //   if (response.data == "success") {
  //     // If the API call is successful, submit the form
  //     event.target.submit();
  //     sessionStorage.setItem("selectedPlanDuration", planDuration);
  //     sessionStorage.setItem("loginPage", "thanking");
  //     sessionStorage.setItem("txnId", txnId);
  //   } else {
  //     // Handle API error
  //     console.error("API call failed");
  //   }
  // } catch (error) {
  //   console.log("error occured:", error);
  // }

  // };

  // Use Effect section
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = " https://jssdk-uat.payu.in/bolt/bolt.min.js"; //"https://jssdk.payu.in/bolt/bolt.min.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);


  useEffect(() => {
    getPayUCredentials();
  }, []);

  useEffect(() => {
    calculatePlanAmount();
  });

  useEffect(() => {
    if (apiKey != null) {
      setHash(
        sha512(
          `${apiKey}|${txnId}|${totalAmount}|${productInfo}|${name}|${mail}|||||||||||${salt}`
        )
      );
    }
  }, [apiKey, totalAmount]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = " https://jssdk-uat.payu.in/bolt/bolt.min.js"; //"https://jssdk.payu.in/bolt/bolt.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   if (bank_ref_num != "") {
  //     signUpPaymentAPI();
  //   }
  // }, [bank_ref_num]);

  if (!paymentModalVisible) return null;
  return (
    <div
      className="plans-modal-overlay"
      onClick={() => {
        dispatch(setPaymentModalVisible(false));
        dispatch(setSelectedPlan(null));
      }}
    >
      <div className="plans-modal" onClick={(e) => e.stopPropagation()}>
        <div
          className="webpage plans-modal-body"
          onClick={(e) => e.stopPropagation()}
        >
          <section id="pricing" className="pricing" style={{ padding: "0px" }}>
            <div
              className="container h-100"
              data-aos="fade-up"
              style={{ height: selectedPlan != null && "95%" }}
            >
              <div className="row gy-4 h-100" data-aos="fade-left">
                <span
                  onClick={() => {
                    dispatch(setSelectedPlan(null));
                    dispatch(setPricingModalVisible(true));
                    dispatch(setPaymentModalVisible(false));
                  }}
                >
                  <ArrowBackIos className="pricing-backButton" />
                </span>
                <div className="h-100 w-50 d-flex justify-content-center border-end border-end-2">
                  {selectedPlan == "Free" && <FreePlanCard />}
                  {selectedPlan == "Starter" && <StarterPlanPaymentCard />}
                  {selectedPlan == "Business" && <BuisnessPlanPaymentCard />}
                </div>
                <CCol
                  className="p-4 d-flex flex-column align-items-center justify-content-between"
                  style={{ margin: "0rem", textAlign: "left" }}
                >
                  <form
                    className="form-elements-container"
                    style={{ height: "98%" }}
                    // onSubmit={handlePaymentFormSubmit}
                  >
                    <div>
                      <h3>Enter Details</h3>
                      <div className="form-inputGroup">
                        <input
                          type="text"
                          name="name"
                          autoComplete="off"
                          value={name}
                          required
                          placeholder="Name"
                          // disabled
                          className="login-input signup-input plan-input"
                        />
                        <FontAwesomeIcon
                          icon={faCircleUser}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                      <div className="form-inputGroup">
                        <input
                          type="email"
                          name="email"
                          autoComplete="off"
                          placeholder="Email Address"
                          value={mail}
                          // onChange={(e) =>
                          //   dispatch(setMail(e.target.value.toLowerCase()))
                          // }
                          className="login-input signup-input plan-input"
                          required
                          // disabled
                        />
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                      <div className="form-inputGroup">
                        <input
                          type="number"
                          name="phone"
                          autoComplete="off"
                          onChange={(e) => dispatch(setPhone(e.target.value))}
                          required
                          placeholder="Mobile"
                          className="login-input signup-input plan-input"
                        />
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="form-inputGroup_Icon"
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-start justify-content-center h-100 payment-radio-button plan-input"
                      style={{ marginLeft: "0.2rem" }}
                    >
                      <span style={{ marginBottom: "0.7rem" }}>
                        Plan Duration (In Months)
                      </span>
                      <div className="form-inputGroup">
                        <CFormCheck
                          inline
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineCheckbox1"
                          value="1"
                          label="1"
                          checked={planDuration === 1}
                          onChange={handleRadioButtonChange}
                          defaultChecked
                        />
                        <CFormCheck
                          inline
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineCheckbox2"
                          value="3"
                          label="3"
                          checked={planDuration === 3}
                          onChange={handleRadioButtonChange}
                        />
                        <CFormCheck
                          inline
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineCheckbox3"
                          value="6"
                          label="6"
                          checked={planDuration === 6}
                          onChange={handleRadioButtonChange}
                        />
                        <CFormCheck
                          inline
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineCheckbox4"
                          value="12"
                          label="12"
                          checked={planDuration === 12}
                          onChange={handleRadioButtonChange}
                        />
                      </div>
                    </div>
                    <div>
                      {/* order summary */}
                      <div className="order-summary">
                        <h3>Order Summary</h3>
                        <div
                          className="order-summary-itemsDiv"
                          style={{ fontSize: "15px" }}
                        >
                          <div className="order-summary-items">
                            <span>Plan Amount: </span>
                            <span>₹{selectedPlanAmount}</span>
                          </div>
                          <div className="order-summary-items">
                            <span>
                              Plan Duration: {planDuration}{" "}
                              {planDuration == 1 ? "Month" : "Months"}
                            </span>
                            <span></span>
                          </div>
                          <div className="order-summary-items border-top border-top-2 border-bottom border-bottom-2">
                            <span>Subtotal: </span>
                            <span>₹{subTotal}</span>
                          </div>
                          <div className="order-summary-items">
                            <span>SGST (9%) : </span>
                            <span>₹{SGST}</span>
                          </div>
                          <div className="order-summary-items">
                            <span>CGST (9%) : </span>
                            <span>₹{CGST}</span>
                          </div>
                          <div className="order-summary-items fw-bold">
                            <span>Total Amount: </span>
                            <span>₹{totalAmount}</span>
                          </div>
                        </div>
                      </div>

                      <CButton
                        color="dark"
                        shape="rounded-pill"
                        className="form-inputGroup_Button"
                        // disabled={name != "" && mail != ""}
                        // type="submit"
                        onClick={handlePaymentFormSubmit}
                      >
                        Pay now
                      </CButton>
                    </div>
                  </form>
                  {/* <form
                    // action={paymentUrl}
                    // method="post"
                    onSubmit={handlePaymentFormSubmit}
                    style={{ marginBottom: "1rem" }}
                  >
                    <input type="hidden" name="key" value={apiKey} />
                    <input type="hidden" name="txnid" value={txnId} />
                    <input
                      type="hidden"
                      name="productinfo"
                      value={productInfo}
                    />
                    <input type="hidden" name="amount" value={totalAmount} />
                    <input type="hidden" name="email" value={mail} />
                    <input type="hidden" name="firstname" value={name} />
                    <input type="hidden" name="surl" value={surl} />
                    <input type="hidden" name="furl" value={furl} />
                    <input type="hidden" name="phone" value={phone} />
                    <input type="hidden" name="hash" value={hash} />
                    <input
                      type="submit"
                      value="Pay now"
                      className="form-inputGroup_Button"
                    />
                  </form> */}
                </CCol>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PaymentAddForm;
