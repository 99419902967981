import React from "react";

function RefundPolicy() {
  return (
    <div>
      <h2>Refund Policy</h2>
      <ol>
        <li>
          <strong>Eligibility:</strong>
          <ul>
            <li>
              Products: Only applicable to physical goods purchased directly
              from us.
            </li>
            <li>Timeframe: 30 days from purchase date.</li>
          </ul>
        </li>
        <li>
          <strong>Process:</strong>
          <ul>
            <li>Request: Contact customer service with proof of purchase.</li>
            <li>Condition: Goods must be unused and in original packaging.</li>
          </ul>
        </li>
        <li>
          <strong>Refund Method:</strong>
          <ul>
            <li>
              Original payment: Refunded to the original method of payment.
            </li>
            <li>Exceptions: Shipping costs are non-refundable.</li>
          </ul>
        </li>
        <li>
          <strong>Processing Time:</strong>
          <ul>
            <li>
              Approval: Refunds processed within 7 business days of approval.
            </li>
            <li>Receipt: Confirmation email sent upon refund completion.</li>
          </ul>
        </li>
        <li>
          <strong>Contact Information:</strong>
          <ul>
            <li>Email: support@[yourcompany].com</li>
            <li>Phone: [Your Company Phone Number]</li>
          </ul>
        </li>
      </ol>
    </div>
  );
}

export default RefundPolicy;
