import { createSlice } from "@reduxjs/toolkit";

const drawingSlice = createSlice({
  name: "drawings",
  initialState: {
    showDrawings: true,
    showAddDocForm: true,
    drawingsListByDoctype: [],
    floorName: "",
    docType: "",
    file: [],
    fileName: "",
  },
  reducers: {
    setShowDrawings: (state, action) => {
      state.showDrawings = action.payload;
    },
    setShowAddDocForm: (state, action) => {
      state.showAddDocForm = action.payload;
    },
    setDrawingsListByDoctype: (state, action) => {
      state.drawingsListByDoctype = action.payload;
    },
    setFloorName: (state, action) => {
      state.floorName = action.payload;
    },
    setDocType: (state, action) => {
      state.docType = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
  },
});

export const {
  setShowDrawings,
  setShowAddDocForm,
  setDrawingsListByDoctype,
  setFloorName,
  setDocType,
  setFile,
  setFileName,
} = drawingSlice.actions;
export default drawingSlice.reducer;
