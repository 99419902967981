import React from "react";
import banner_8 from "../../../webassets/img/pricing-starter.png";
import { useDispatch } from "react-redux";
import {
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
  setSelectedPlanAmount,
  setSelectedUpgradePlan,
  setUpgradePaymentModal,
  setUpgradePlansModal,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";

function UpgradeStarterPlan() {
  const dispatch = useDispatch();
  const selectedUpgradePlan = useSelector(
    (state) => state.plans.selectedUpgradePlan
  );
  const upgradePlansModal = useSelector(
    (state) => state.plans.upgradePlansModal
  );
  const currentPlan = useSelector((state) => state.dashboard.plan);
  return (
    <div
      className={`${
        selectedUpgradePlan == "Starter" ? "col-lg-8" : "col-lg-6"
      } col-md-6 paymentForm-allUICard`}
      data-aos="zoom-in"
      data-aos-delay={200}
    >
      <div
        className={`box ${
          selectedUpgradePlan != null ? "h-90" : "paymentForm-box"
        }`}
        // style={{ height: `${selectedUpgradePlan == null ? "auto" : "96%"}` }}
      >
        <h3 style={{ color: "#65c600" }} className="mb-0">
          Starter Plan
        </h3>
        <div className="price">
          <sup>₹</sup>2999<span> +GST / mo</span>
        </div>
        <img src={banner_8} className="img-fluid" alt="" />
        <ul>
          <li>1 Administrator</li>
          <li>3 Team Members</li>
          <li>10 Mobile App Accounts</li>
          <li>10 Projects</li>
          <li>5GB Cloud Storage</li>
          <li>Add Storage - 10GB @ ₹275/mo</li>
          <li className="na">Additional Administrators</li>
          <li className="na">Add Team Members</li>
        </ul>
        {selectedUpgradePlan == null && (
          <a
            href="#"
            className="btn-buy"
            onClick={() => {
              dispatch(setSelectedUpgradePlan("Starter"));
              dispatch(setSelectedPlanAmount(2999));
              dispatch(setUpgradePlansModal(false));
              dispatch(setUpgradePaymentModal(true));
              localStorage.setItem("selectedPlan", "Starter");
            }}
          >
            Select
          </a>
        )}
      </div>
    </div>
  );
}

export default UpgradeStarterPlan;
