import React, { useState, useRef } from "react";
import { CButton, CCol, CRow, CSpinner } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pwdImage from '../../assets/images/pwd-image.jpg';
import '../../webassets/css/style.scss';

import { faEnvelope,  faCircleUser,
  faEye,
  faEyeSlash,
  faUser,
  faCircleInfo,
  faBriefcase, } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Sliders from "./Slider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoader,
  setMail,
  setName,
  setPassword,
} from "src/redux/slices/UserRegistrationSlice";
import API_URL from "src/utils/urls";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import cryptoJs from 'crypto-js'; 

const USER_REGEX_LETTER = /^[a-zA-Z]/;
const USER_REGEX_LENGTH = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX_LOWER = /^(?=.*[a-z])/;
const PWD_REGEX_UPPER = /^(?=.*[A-Z])/;
const PWD_REGEX_NUMBER = /^(?=.*[0-9])/;
const PWD_REGEX_SPECIAL = /^(?=.*[!@#$%])/;
const PWD_REGEX_LENGTH = /^.{8,24}$/;

  const ForgotPassword = () => {
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [step, setStep] = useState("request");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Password validation
  const validatePasswordComplexity = () => {
    const hasLower = PWD_REGEX_LOWER.test(password);
    const hasUpper = PWD_REGEX_UPPER.test(password);
    const hasNumber = PWD_REGEX_NUMBER.test(password);
    const hasSpecial = PWD_REGEX_SPECIAL.test(password);
    const validLengthPwd = PWD_REGEX_LENGTH.test(password);

  // Reset validation states
  setValidPassword(true);
  setValidMatch(true);;

    if (!hasLower) {
      toast.error("Password Must Contain At Least One Lowercase Letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
    }

    if (!hasUpper) {
      toast.error("Password Must Contain At Least One Uppercase Letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);    
    }

    if (!hasNumber) {
      toast.error("Password Must Contain At Least One Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
    }

    if (!hasSpecial) {
      toast.error(
        "Password Must Contain At Least One Special Character (!@#$%)",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setValidPassword(false);
    }

    if (!validLengthPwd) {
      toast.error("Password Should Be Between 8 to 24 Characters", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidPassword(false);
    }

    // Confirmation password validation
    if (password !== cnfPassword) {
      toast.error("Confirmation Password Does Not Match", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setValidMatch(false); 
    }
    return validPassword && validMatch;
  };
  
  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    if (!validateEmail(mail)) {
      setEmailError("Invalid email address");
      return;
    }
    setEmailError("");
    setLoading(true);
    sendResetLink();
  };

  const sendResetLink = async () => {
    try {
      let urlForPWDOTP = API_URL + "sendPwdOtpToEmail";
      const response = await axios.get(urlForPWDOTP, {
        params: { email: mail },
      });

      setLoading(false);

      if (response.data.result === "Success") {
        toast.success("OTP sent to your email", {
          position: toast.POSITION.TOP_RIGHT,
        });
       // setStoredOtp(response.data.otp); // Store the OTP received from the server
        setStep("otp");
        handleClickOpen(); // Open the next section
   
      } else {
        toast.error(response.data.reason || "Failed to send OTP. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    setLoading(true);
    try {
      let urlResend = API_URL+"sendPwdOtpToEmail";
      const response = await axios.get(urlResend, {
        params: { email: mail },
      });

      if (response.data.result === "Success") {
        toast.success("OTP resent to your email", {
          position: toast.POSITION.TOP_RIGHT,
        });
         setStep("otp");
         handleClickOpen();  
      } else {
        toast.error(response.data.reason || "Failed to resend OTP. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let urlForPWDOTP = API_URL + "verifyOTP";
      const response = await axios.get(urlForPWDOTP, {
        params: {
          email: mail,  // replace 'mail' with the actual email variable
          otp: otp.join(""), // join the OTP array to form the full OTP string
        } 
      });
  
  
      if (response.data.result === "Success") {
        toast.success("OTP verified successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClickOpen(); // Open the next section
       setStep("reset") // Navigate to the reset password page
       
      } else {
        toast.error(`Invalid OTP. ${response.data.reason || "Please try again."}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to hash a value using SHA-512
const sha512 = (value) => {
  const hash = cryptoJs.SHA512(value);
  return hash.toString(cryptoJs.enc.Hex);
};
  
  const handlePasswordReset = async (e) => {
    e.preventDefault();
  
  // Validate password complexity and confirmation
  const isValid = validatePasswordComplexity();

  if (!isValid) {
    return; // Do not proceed if validation fails
  }
  
    setLoading(true);
    try {
      const hashedPassword = sha512(password);
      let urlReset = API_URL+"resetPassword";
      await axios.post(urlReset, {
        email: mail,
        password: hashedPassword,
      });


      setStep("confirmation");
      handleClickOpen();
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClickSignIn = () => {
    // Redirect to sign-in page or perform other actions
    window.location.href = '/signin';
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div style={{ height: "30rem" }}>
          <CSpinner style={{ position: "absolute", top: "50%", left: "48%" }} />
        </div>
      ) : (
        <>
          {step === "request" && (
            <CRow className="align-items-center justify-content-around">
              <CCol md={6} className="p-4 d-flex flex-column align-items-center form-container">
                <h2 className="form-heading">Forgot Password</h2>
                <div className="d-flex flex-column align-items-center justify-content-around h-100">
                  <form onSubmit={handleSubmitEmail}>
                    <div className="form-inputGroup">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email address"
                        onChange={(e) => setMail(e.target.value.toLowerCase())}
                        className="login-input"
                        autoComplete="off"
                        required
                      />
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="form-inputGroup_Icon"
                      />
                    </div>
                    <CButton
                      color="dark"
                      shape="rounded-pill"
                      className="form-inputGroup_Button"
                      type="submit"
                      disabled={!validateEmail(mail) || loading}
                    >
                      Send Reset Link
                    </CButton>
                    {emailError && <p className="error-text">{emailError}</p>}
                  </form>
                  <div className="form-inputGroup_Para w-100">
                    <span>Remembered your password?</span>
                    <a className="form-inputGroup_Para-link" href="/sign-in">
                      Sign In here
                    </a>
                  </div>
                </div>
              </CCol>
              <div className="vr"></div>
              <CCol md={5} className="text-center">
                <Sliders />
              </CCol>
            </CRow>
          )}

          {step === "otp" && open && (
            <CRow className="align-items-center justify-content-center">
              <CCol md={6} className="p-4 form-container">
                <h2 className="form-heading">Enter OTP</h2>
                <p>Your One Time Password(OTP) has been sent to your registered mail address</p>
                <br />
                <br />
                <div className="otp-inputs">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="text"
                      className="otp-input"
                      value={otp[index]}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      maxLength="1"
                    />
                  ))}
                </div>
                <br />
                <br />
                <div className="form-inputGroup_Para w-100 ">
                   <span>Didn't receive the OTP?</span>
                    <a className="form-inputGroup_Para-link" href="#"
                      onClick={handleResendOTP} >
                      Resend OTP  
                     </a>
                </div>

                <CButton
                  color="dark"
                  shape="rounded-pill"
                  className="form-inputGroup_Button"
                  onClick={handleOtpSubmit}
                  disabled={otp.includes("") || loading}
                  style={{marginTop:"10px"}}
                >
                  Verify OTP
                </CButton>
              </CCol>
              <div className="vr"></div>
              <CCol md={5} className="text-center">
                <Sliders />
              </CCol>
            </CRow>
          )}

          {step === "reset" &&  open && (
            <CRow className="align-items-center justify-content-center">
              <CCol md={6} className="p-4 form-container">
                <h2 className="form-heading">Reset Password</h2>
               
                <br />
                
                <form onSubmit={handlePasswordReset}>
                  <div className="form-inputGroup">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      placeholder="Enter new password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="login-input"
                      autoComplete="off"
                      required
                    />
                  <FontAwesomeIcon
                   icon={passwordVisible ? faEye : faEyeSlash}
            onClick={() => setPasswordVisible(!passwordVisible)}
            className="form-inputGroup_Icon"
            style={{ cursor: "pointer", marginRight: "20px" }}
                  />               
                  </div>
                  
                  <div className="form-inputGroup">
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm new password"
                      onChange={(e) => setCnfPassword(e.target.value)}
                      className="login-input"
                      autoComplete="off"
                      required
                    />
                                      <FontAwesomeIcon
                   icon={passwordVisible ? faEye : faEyeSlash}
            onClick={() => setPasswordVisible(!passwordVisible)}
            className="form-inputGroup_Icon"
            style={{ cursor: "pointer", marginRight: "20px" }}
                  />  

         
                  </div>
                  <br />
                  <br/>
                  <CButton
                    color="dark"
                    shape="rounded-pill"
                    className="form-inputGroup_Button"
                    disabled={loading}
                    type="submit"
                    >
                      Reset Password
                    </CButton>
                </form>
              </CCol>
              <div className="vr"></div>
              <CCol md={5} className="text-center">
                <Sliders />
              </CCol>
            </CRow>
          )}
           
          {step === "confirmation" && open && (
          <CRow className="align-items-center justify-content-center">
             <CCol md={8} className="confirmation-content">  {/* 60% width */}
                <h2 className="confirmation-heading">Password Reset Successful</h2>
                 <p className="confirmation-text">Your password has been successfully reset. You can now log in with your new password.</p>
                <CButton
                  color="dark"
                  shape="rounded-pill"
                  className="confirmation-button"
                  onClick={handleClickSignIn}
               >
                 Please Sign In
                </CButton>
              </CCol>
              <CCol md={4} className="confirmation-image-wrapper">  {/* 40% width */}
               <img 
               src={pwdImage} 
              alt="Password Reset Success" 
              className="confirmation-image"
               />
              </CCol>
          </CRow>
          )}

        </>
      )}
    </>
  );
};

export default ForgotPassword;
